import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import {BarChart, Bar, XAxis, YAxis} from 'recharts';


const useStyles = makeStyles(
  (theme: Theme) => createStyles(
  {
    root: {
      '&::before': {
        content: "''",
        position: "absolute",
        top: 0,
        left: 0,
        borderTop: "30px solid #282c34",
        borderRight: "30px solid #400614",
        width: 0,
      },
     '&::after': {
        content: "''",
        position: "absolute",
        bottom: 0,
        right: 0,
        borderBottom: "30px solid #282c34",
        borderLeft: "30px solid #400614",
        width: 0,
      },
      maxWidth: 800,
      // maxHeight: 650,
      padding: 25,
      position: "relative",
      backgroundColor: "#400614"
    },
    media: {
      height: 300,
    },
    paperleft: {
      textAlign: 'left',
      fontFamily: "Protomolecule",
      color: "white"
    },
    paper: {
      textAlign: 'center',
      fontFamily: "Protomolecule",
      color: "white"
    },
    paperright: {
      textAlign: 'right',
      fontFamily: "Protomolecule",
      color: "white"
    },
    flavortext: {
      textAlign: 'center',
      fontFamily: "Protomolecule",
      fontStyle: "italic",
      color: "grey"
    }
  })
);

interface StatElements {
  category: string;
  value: number;
}

interface CocktailProps {
  name: string;
  category: string;
  origin: string;
  image: string;
  description: string;
  flavorText: string;
  stats: Array<StatElements>;
}
export default function CocktailCard(props: CocktailProps) {
  const classes = useStyles();
  const {
    name,
    category,
    origin,
    image,
    description,
    flavorText,
    stats
  } = props

  return (
    <Card className={classes.root}>
      <Grid container spacing={3}>
        <Grid item sm={8} xs={12}>
          <Typography variant="h3" gutterBottom className={classes.paperleft}>{name}</Typography>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Typography variant="h6"className={classes.paperright}>{category.toLowerCase()}</Typography>
          <Typography variant="h6"className={classes.paperright}>origin: {origin.toLowerCase()}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <img src={image} alt={name}/>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography className={classes.paper} style={{height: 150}}>{description.toUpperCase()}</Typography>
          <BarChart
            width={350}
            height={200}
            data={stats}
            layout="vertical"
            barCategoryGap="2%"
            barGap={1}
          >
            <defs>
              <linearGradient
                id="colorUv"
                x1="0"
                y1="0"
                x2="100%"
                y2="0"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="yellow" />
                <stop offset="1" stopColor="red" />
              </linearGradient>
            </defs>
            <YAxis type="category" style={{ fill: "#62c4fc"}} tick={{fontSize: 12, fontFamily: 'Protomolecule'}} dataKey="category"/>
            <XAxis type="number" style={{ fill: "#62c4fc"}} tick={{fontSize: 12, fontFamily: 'Protomolecule'}} domain={[0, 5]}/>
            <Bar dataKey="value" fill="url(#colorUv)" minPointSize={3} barSize={10}/>
          </BarChart>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.flavortext}>{flavorText.toLowerCase()}</Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
