import React from 'react';
import {Switch, Route} from "react-router-dom";

import Landing from "./pages/Landing"
import Featured from "./pages/Featured"


export default function Routes() {
  return (
    <Switch>
      <Route path="/featured" children={<Featured />} />
      <Route path="/" children={<Landing />} />
    </Switch>
  );
}
