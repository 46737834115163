import React from 'react';
import Typography from '@material-ui/core/Typography';

import CocktailCard from '../components/cocktail/cocktail'
import eyeOfJupiter from "../images/drinks/eyeofjupiter.png"
import Copyright from "../components/Copyright"


export default function Featured(){
    return (
      <header className="App-header">
        <Typography
            variant="h1"
          style={{ fontFamily: "Protomolecule", color: "white" }}
        >
            Featured Jovian Cocktails
        </Typography>
        <br/>
        <CocktailCard
          name="The Eye Of Jupiter"
          description="A HIBISCUS INFUSED RUM IS USED IN THIS CLASSIC DAISY.  SERVED OVER A PILE OF HAND SHAVED ICE THE TART LIME AND HIBISCUS CUT THROUGH THE RICH ORANGE AND DEMERARA."
          stats={[{category: 'Strong',value: 2},{category: 'Sweet',value: 1.5},{category: 'Tart',value: 2.5},{category: 'Bitter',value: 0},{category: 'Aromatic',value: 0.5}]}
          flavorText='"In the violence of the cosmos, there is also beauty."'
          category="daisy"
          origin="moj"
          image={eyeOfJupiter}
        />
        <br/>
        <Copyright/>
      </header>
    )
}
