import React from 'react';
import { Theme } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { ReactComponent as JupiterLogo } from '../images/jupiter_red_spot.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flex: 1
    },
    main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(10),
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
  },
    jupLogo: {
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme
        .spacing(3)}px`
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    spacer: {
      marginTop: theme.spacing(15)
    }
  })
);

function Copyright() {
  return (
    <Typography variant="body2" color="inherit" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://vega-labs.com">
        https://vega-labs.com
      </Link>{' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );
}

export default function Landing(){
    const classes = useStyles();

    return (
        <>
          <header className="App-header">
            <Typography
                variant="h1"
              style={{ fontFamily: "Protomolecule", color: "white" }}
            >
                The 79 Moons Of Jupiter
            </Typography>
            <Container maxWidth="lg" className={classes.container}>
                <JupiterLogo className={classes.jupLogo}/>
            </Container>
            <Typography
                variant="h6"
              style={{ fontFamily: "Protomolecule", color: "white" }}
            >
                The bar that will become a genre unto itself
            </Typography>
            <div className={classes.spacer}>
                <a href="mailto:ross@vega-labs.com" target="_top">
               <Typography variant="h6" style={{ fontFamily: "Protomolecule", color: "white" }}>
                 INTERESTED? SEND US A TIGHTBEAM
                </Typography>
              </a>
            </div>
              <Copyright />
          </header>
        </>
    )
}
