import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';

import './App.css';

import Routes from './Routes';

export default function App() {
  return (
    <div className="App">
      <Router>
         <CssBaseline />
         <Routes/>
      </Router>
    </div>
  );
}
